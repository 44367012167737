var data = [{
  'label': '2.0.1',
  'date': '2022-01-24T12:57:00.000000',
  'id': 1,
  'development': [{
    'label': 'Oncology vue kod şablonu başlangıç versiyonu.',
    'component': '',
  }, {
    'label': 'Toplam 31 adet tablo oluşturuldu.',
    'component': '',
  }, {
    'label': 'Toplam 18 adet komponent geliştirildi.',
    'component': '',
  }, {
    'label': 'Vücut yüzey alanı komponenti geliştirildi.',
    'component': '<Bsa>',
  }, {
    'label': 'Tanı seçim komponenti geliştirildi.',
    'component': '<DiagnosisSelection>',
  }, {
    'label': 'İlaç detayları düzenleme komponenti geliştirildi.',
    'component': '<DrugBody>',
  }, {
    'label': 'İlaç başlığı gösterimi komponenti geliştirildi.',
    'component': '<DrugHeader>',
  }, {
    'label': 'Açılan ekran komponenti geliştirildi.',
    'component': '<Modal>',
  }, {
    'label': 'Şema ilaç listesi komponenti geliştirildi.',
    'component': '<SchemaDrugs>',
  }, {
    'label': 'Şema ilaçları ayarları düzenleme komponenti geliştirildi.',
    'component': '<SchemaDrugsSettings>',
  }, {
    'label': 'Şema seçimi komponenti geliştirildi.',
    'component': '<SchemaSelection>',
  }, {
    'label': 'Tedavi siklus gün düzenleme komponenti geliştirildi.',
    'component': '<TreatmentCycleDayArrange>',
  }, {
    'label': 'Tedavi siklus artırma komponenti geliştirildi.',
    'component': '<TreatmentCycleIncrease>',
  }, {
    'label': 'Tedavi tarih düzenleme komponenti geliştirildi.',
    'component': '<TreatmentDateChange>',
  }, {
    'label': 'Tedavi gün komponenti geliştirildi.',
    'component': '<TreatmentDay>',
  }, {
    'label': 'Tedavi gün detayları komponenti geliştirildi.',
    'component': '<TreatmentDayBody>',
  }, {
    'label': 'Tedavi gününe ait ilaçlar için çoklu onay komponenti geliştirildi.',
    'component': '<TreatmentDayButtons>',
  }, {
    'label': 'Tedavi günündeki ilaçarına ait gün başlığı komponenti geliştirildi.',
    'component': '<TreatmentDayHeader>',
  }, {
    'label': 'Tedavi ilaç uygulamalarını düzenleme komponenti geliştirildi.',
    'component': '<TreatmentDrugArrange>',
  }, {
    'label': 'Tedavi ilaçları onay butonları komponenti geliştirildi.',
    'component': '<TreatmentDrugButtons>',
  }, {
    'label': 'Hasta tedavi listesi komponenti geliştirildi.',
    'component': '<TreatmentListWidget>',
  }]
}];

export { data as options };
